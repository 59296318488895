.CalculatorReadOnlyValue {
    width: 100%;
    font-size: 22px;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    border: 0px;
    background-color: #2B2B2B;
    color: #9D9D9D;
    opacity: 0.5;
    box-sizing: border-box;
}

.CalculatorReadOnlyValue.lighten {
    opacity: 0.3;
}

.CalculatorReadOnlyValue-prefix {
    margin-right: 5px;
}