.CalculatorInputGroup {
    display: block;
    background-color: #3B3B3B;
    border-radius: 20px;
    padding-top: 25px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 25px;
    margin-bottom: 30px;
}

.CalculatorInputGroup-container {
    display: flex;
    flex-direction: column;
}