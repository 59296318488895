.CalculatorSearchInputOption {
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 10px;
    text-align: left;
    color: #FFFFFF;
    cursor: pointer;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #3A3A3A;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
}

.CalculatorSearchInputOption:hover {
    background-color: rgba(255,255,255,0.07);
}

.CalculatorSearchInputOption .CalculatorSearchInputOption-label {
    font-size: 22px;
}