.CalculatorInput.invalid input {
    border: 2px solid #B30000;
}

.CalculatorInput input, .CalculatorInput select, .CalculatorInput textarea {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background-color: #2B2B2B;
    border-radius: 10px;
    border: 0px;
    color: #9D9D9D;
    font-size: 22px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.CalculatorInput input {
    -moz-appearance: textfield;
}

.CalculatorInput input:focus, .CalculatorInput select:focus, .CalculatorInput textarea:focus {
    outline: none;
}