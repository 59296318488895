body, html {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0px;
    background-color: #262626;
    color: #9D9D9D;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
    padding: 10px;
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.App::-webkit-scrollbar {
    display: none;
}

.App-back-arrow {
    display: block;
    padding-top: 10px;
    text-align: left;
    width: 100%;
    margin-bottom: auto;
}

.App-back-arrow.hide {
    display: none;
}

.App-back-arrow-container {
    width: 50px;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
    opacity: 0.8;
}

.App-back-arrow-container:hover {
    opacity: 1;
}

.App-back-arrow img {
    max-width: 100%;
}