.MenuItem {
    display: block;
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
    font-size: 18px;
}

.MenuItem:last-child {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.MenuItem:hover {
    background-color: rgba(255,255,255,0.07);
}