.CalculatorSearchInput {
    position: relative;
}

.CalculatorSearchInput.focused .CalculatorSearchInput-field input {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.CalculatorSearchInput .CalculatorSearchInput-options {
    position: absolute;
    display: none;
    width: 100%;
    background-color: #2B2B2B;
    max-height: 250px;
    overflow-y: scroll;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    z-index: 5;
}

.CalculatorSearchInput.focused .CalculatorSearchInput-options {
    display: block;
}