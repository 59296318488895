.CalculatorResultGroup {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    color: #FFFFFF;
}

.CalculatorResultGroup .CalculatorResultGroup-equals {
    display: block;
    font-size: 40px;
    font-weight: bold;
    margin-right: 15px;
    margin-left: 15px;
    box-sizing: border-box;
}

.CalculatorResultGroup .CalculatorResultGroup-result {
    display: block;
    background-color: #3B3B3B;
    border-radius: 20px;
    padding-top: 25px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 25px;
    cursor: pointer;
    box-sizing: border-box;
    height: 90px;
}

.CalculatorResultGroup .CalculatorResultGroup-result.lighten {
    opacity: 0.8;
}

.CalculatorResultGroup .CalculatorResultGroup-result .CalculatorResultGroup-currency, .CalculatorResultGroup .CalculatorResultGroup-result .CalculatorResultGroup-value {
    font-size: 32px;
    margin-right: 10px;
}

.CalculatorResultGroup .CalculatorResultGroup-result .CalculatorResultGroup-units {
    font-size: 14px;
}