.CalculatorReadOnlyColumn {
    display: block;
    flex: 1;
    min-width: 275px;
}

@media screen and (min-width: 768px) {
    .CalculatorReadOnlyColumn.CalculatorReadOnlyColumn-small {
        flex: 0.5;
    }
}
