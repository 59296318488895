.CalculatorInputGroupRow {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
}

.CalculatorInputGroupRow-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}