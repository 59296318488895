.Menu {
    display: block;
    width: 100%;
    max-width: 725px;
    margin-right: auto;
    margin-left: auto;
    background-color: #3B3B3B;
    border-radius: 10px;
}

.Menu-logo {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.Menu-logo img {
    height: 100px;
    max-width: 100%;
}